import { template as template_1c026cd1e04c4863b375edd251088594 } from "@ember/template-compiler";
const FKText = template_1c026cd1e04c4863b375edd251088594(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
