import { template as template_1f06186c4e3e45569b6eaae8ddfaca0a } from "@ember/template-compiler";
const WelcomeHeader = template_1f06186c4e3e45569b6eaae8ddfaca0a(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
