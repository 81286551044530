import { template as template_2ffc4fe7353a4305b8659881fe0a81b3 } from "@ember/template-compiler";
const FKControlMenuContainer = template_2ffc4fe7353a4305b8659881fe0a81b3(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
